<template>
  <div class="row">
    <div class="col-md-9">
      <div class="col-md-12 mb-1 form-group form-outline" name="Nome do Titulo">
        <label class="mb-1 label_default">
          Título
        </label>
        <input
          class="form-control input_default"
          maxlength="50"
          :value="title"
          @input="$emit('update:title', $event.target.value)"
        />
      </div>
      <div class="col-md-12" rules="max:200" name="Descricao">
        <label class="mb-1 label_default">
          Anotações
        </label>
        <textarea
          class="textarea_default w-100"
          :value="description"
          @input="$emit('update:description', $event.target.value)"
          maxlength="200"
        ></textarea>
        <div class="d-flex">
          <div class="col-md-4 px-0">
            <span class="contador">{{ description.length }}/ 200</span>
          </div>
          <div class="col-md-4 ml-auto px-0 text-right">
            <a
              class="mb-0 p_destaque"
              @click.prevent="$emit('update:spotlight', !spotlight)"
              :class="spotlight ? 'p_destaque_active' : 'p_destaque'"
            >
              Destaque
              <i class="fas fa-star"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-12 pt-2">
        <button
          class="btn btn_delete m-auto"
          v-if="sequence != undefined"
          @click.prevent="$emit('delInstruction', sequence)"
        >
          Excluir
          <i class="fas fa-trash-alt ml-1"></i>
        </button>
        <button
          class="btn btn_default m-auto"
          v-else
          @click.prevent="newInstruction()"
        >
          + Adicionar
        </button>
      </div>
    </div>
    <div class="col-md-3 mt-4">
      <div class="box_image position-relative">
        <img
          :src="
            image != null
              ? image.includes('data')
                ? image
                : getImg(image)
              : ''
          "
          class="w-100"
          @error="errorImage2"
        />
        <input
          class="btnAlteraFoto position-absolute"
          type="file"
          :id="`file_${sequence == undefined ? 0 : sequence}`"
          accept="image/x-png, image/gif, image/jpeg"
          placeholder="Alterar"
          @change="onChoseFile($event)"
        />
      </div>
    </div>
    <hr class="w-100" />
  </div>
</template>

<script>
export default {
  props: ["title", "description", "spotlight", "image", "sequence"],
  methods: {
    onChoseFile(event) {
      console.log("event", event);
      var input = event.target;
      if (input.files && input.files[0]) {
        if (Math.round(input.files[0].size / 1024) >= 1000) {
          this.toast(
            `Arquivo maior que 1MB! Por favor ultilize um compressor ou selecione outro arquivo! \r Tamanho: ${Math.round(
              input.files[0].size / 1024
            )}KB`,
            this.$toast.success
          );

          $(`#${input.id}`).val("");
          return false;
        }

        var reader = new FileReader();
        reader.onload = e => {
          this.$emit("update:image", e.target.result);
          // console.log("myFiles", input.files);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    newInstruction() {
      this.$emit("newInstruction");
      $(`#file_${this.sequence == undefined ? 0 : this.sequence}`).val("");
    }
  }
};
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
@media screen and (max-width: 767px) {
  .box_image {
    width: 100% !important;
  }
}
.p_destaque {
  color: #e3e3e3;
  font-size: 16px;
  font-weight: 700;
  display: block;
  transition: all 0.2s linear;
  cursor: pointer;
}
.p_destaque:hover {
  color: #e0a638;
}
.p_destaque_active {
  color: #e0a638 !important;
  transition: all 0.2s linear;
}
hr {
  border-top: 2px solid #f4f4f4 !important;
}
.box_image {
  width: 136px;
  border-radius: 20px;
  height: 140px;
}
.box_image img {
  height: 100%;
  object-fit: cover;
}
.box_image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.box_image:hover::after {
  contain: "";
  opacity: 0.8;
}

.btnAlteraFoto::-webkit-file-upload-button {
  visibility: hidden;
}
.btnAlteraFoto::after {
  content: "Alterar foto";
  color: #fff;
  outline: none;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 11px;
  opacity: 0;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
.box_image:hover .btnAlteraFoto::after {
  opacity: 1;
}
.btnAlteraFoto {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 22;
}
@media screen and (max-width:767px) {
  .btnAlteraFoto {
  z-index: 1!important;
}
}
</style>
